@import 'vars';

html, body {
  position: relative;
  font-family: $montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: $blackColor;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
body {
  &::-webkit-scrollbar {
    width: 8px;
    background-color: $lightGrayColor;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $darkCreamColor;
    border-radius: 8px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: $orangeColor;
  }
}
.menu-opened {
  overflow-y: hidden;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $blackColor;
    opacity: 0.8;
    z-index: 50;
  }
}
a {
  text-decoration: none;
  color: $blackColor;
}
h1, h2, h3, h4, h5 {
  margin: 0;
  padding: 0;
}
p {
  margin: 0;
  padding: 0;
}
.ul {
  &_spaces {
    &--none {
      margin: 0;
      padding: 0;
    }
  }
}
.li {
  &_style {
    &--none {
      list-style: none;
    }
  }
}
.main {
  min-height: 100vh;
}
.container {
  max-width: 1920px;
  margin: auto;
  padding: 0 16px;
}
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  max-width: 224px;
  min-width: 224px;
  height: 48px;
  font-size: 14px;
  line-height: 17px;
  color: $orangeColor;
  text-transform: uppercase;
  background-color: $creamColor;
  outline: none;
  border: 1px solid $orangeColor;
  cursor: pointer;
  padding: 16px 32px;
  transition: background-color 0.5s;
  &:hover {
    background-color: $orangeColor;
    color: $whiteColor;
  }
  &_color {
    &--orange {
      background-color: $orangeColor;
      color: $whiteColor;
    }
  }
}

/** MAIN HEADER **/
.main-header {
  position: relative;
  background-color: $creamColor;
  &__wrapper {
    width: 100%;
    max-width: 1528px;
    margin: auto;
  }
  &__container {
    display: grid;
    grid-template-columns: 1fr 160px 224px;
    align-items: center;
    grid-gap: 24px;
    transition: left 0.5s;
  }
  &__burger {
    display: none;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    margin-left: auto;
    width: 32px;
    height: 24px;
    &:hover {
      cursor: pointer;
    }
  }
  &__burger-line {
    display: block;
    width: 100%;
    height: 1px;
    background-color: $orangeColor;
  }
  &__close {
    display: none;
    position: absolute;
    top: 8px;
    right: 8px;
    width: 24px;
    height: 24px;
    &:hover {
      cursor: pointer;
    }
    &:before, &:after {
      position: absolute;
      left: 11px;
      content: '';
      height: 24px;
      width: 2px;
      background-color: $lightGrayColor;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
  &__logo {
    width: 295px;
    height: 64px;
  }
  &__navigation {
    display: flex;
    justify-content: center;
  }
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
    border-bottom: 1px solid $lightCreamColor;
  }
  &__bottom {
    display: grid;
    grid-template-columns: 296px 1fr;
    grid-gap: 24px;
    align-items: center;
    padding: 24px 0;
  }
  &__right {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }
  &__socials {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 24px;
    margin-left: auto;
  }
  &__social {
    display: block;
  }
  &__emails {
    position: relative;
    margin-left: 48px;
    &:before {
      content: '';
      position: absolute;
      left: -24px;
      width: 1px;
      height: 100%;
      border-right: 1px solid $orangeColor;
    }
  }
  &__email-items {
    display: grid;
    grid-template-columns: 1fr;
  }
  &__email {
    font-size: 14px;
    color: $orangeColor;
    text-decoration: underline;
  }
  &__container-socials, &__container-emails {
    display: none;
  }
}
@media (max-width: 1439px) {
  .main-header {
    &__top {
      padding: 8px 0;
    }
    &__bottom {
      padding: 0;
    }
    &__right {
      display: none;
    }
    &__bottom {
      grid-template-columns: 1fr 32px;
    }
    &__logo {
      position: relative;
      width: 100%;
      max-width: 256px;
      z-index: 60;
    }
    &__container {
      position: absolute;
      top: 0;
      left: -304px;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 64px auto auto 120px;
      align-items: baseline;
      width: 100%;
      max-width: 304px;
      height: 100vh;
      background-color: $whiteColor;
      z-index: 60;
      &_state {
        &--open {
          left: 0;
          overflow-y: auto;
        }
      }
    }
    &__navigation {
      justify-content: flex-start;
      margin-top: 128px;
      padding: 0 24px;
    }
    &__button {
      padding: 0 24px;
    }
    &__contacts {
      padding: 0 24px;
    }
    &__burger {
      display: grid;
    }
    &__close {
      display: block;
    }
    &__container-socials, &__container-emails {
      display: block;
      padding: 0 24px;
    }
    &__container-socials {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(24px, 1fr));
      grid-gap: 8px;
    }
    &__container-emails {
      display: grid;
      grid-template-columns: 1fr;
    }
  }
}
/** HEADER END **/

/** TOWN-SELECTION **/
.town-selection {
  position: relative;
  &__icon {
    transition: all 0.5s;
    &_state {
      &--open {
        transform: rotate(180deg);
      }
    }
  }
  &__dropdown {
    display: none;
    position: absolute;
    right: 0;
    left: 0;
    background-color: $creamColor;
    border: 1px solid $lightCreamColor;
    z-index: 20;
  }
  &__items {
    display: grid;
    gap: 8px;
    padding: 8px;
  }
  &__item {
    font-size: 14px;
    transition: color 0.2s;
    &:hover {
      color: $orangeColor;
    }
  }
  &__current {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 8px;
    align-items: center;
  }
  &__text {
    font-size: 14px;
    line-height: 20px;
  }
  &__town {
    font-weight: 500;
    line-height: 20px;
    border-bottom: 1px dashed $blackColor;
    &:hover {
      cursor: pointer;
    }
  }
  &__arrow {
    width: 8px;
    height: 8px;
    fill: $blackColor;
  }
}
@media (max-width: 1439px) {
  .town-selection {
    z-index: 70;
    &__town {
      font-size: 14px;
    }
  }
}
/** TOWN-SELECTION END **/

/** SOCIAL **/
.social {
  width: 24px;
  height: 24px;
  &__icon {
    width: 100%;
    height: 100%;
  }
}
/** SOCIAL END **/

/** LOGO **/
.logo {
  &__link {
    display: block;
    width: 100%;
    height: 100%;
  }
  &__image {
    width: 100%;
    height: 100%;
  }
}
/** LOGO END **/

/** NAVBAR **/
.navbar {
  &__list {
    display: flex;
  }
  &__item {
    &:not(:last-child) {
      margin-right: 48px;
    }
    &_dropdown {
      position: relative;
      display: flex;
      align-items: center;
    }
  }
  &__link {
    font-size: 14px;
    transition: color 0.2s;
    &:hover {
      color: $orangeColor;
      & + .navbar__icon path {
        fill: $orangeColor;
      }
    }
  }
  &__icon {
    margin-left: 8px;
    & path {
      transition: fill 0.2s;
    }
  }
  &__dropdown {
    display: none;
    position: absolute;
    top: 40px;
    right: 0;
    left: 0;
    width: 320px;
    background-color: $creamColor;
    border: 1px solid $lightCreamColor;
    border-radius: 8px;
    z-index: 10;
  }
  &__dropdown-items {
    display: grid;
    gap: 8px;
    padding: 8px 24px;
  }
  &__dropdown-item {
    font-weight: 500;
    transition: color .2s;
    &:hover {
      color: $orangeColor;
    }
  }
  &__dropdown-message {
    padding: 24px;
    &-text {
      font-weight: 500;
      text-align: center;
    }
  }
}
@media (max-width: 1439px) {
  .navbar {
    &__list {
      flex-flow: column wrap;
    }
    &__item {
      display: flex;
      flex-flow: column wrap;
      align-items: baseline;
      &_dropdown {
        align-items: flex-start;
      }
    }
    &__item:not(:last-child) {
      margin-right: 0;
      margin-bottom: 16px;
    }
    &__link {
      position: relative;
    }
    &__icon {
      position: absolute;
      right: -16px;
    }
    &__dropdown {
      position: static;
      width: 100%;
      background: $whiteColor;
      border: none;
    }
    &__dropdown-item {
      font-size: 14px;
      font-weight: 400;
    }
  }
}
/** NAVBAR END **/

/** CONTACTS-INFO **/
.contacts-info {
  &__phone-items {
    display: grid;
    grid-template-columns: 1fr;
  }
  &__phone {
    font-weight: 500;
    transition: color 0.2s;
    &:hover {
      color: $orangeColor;
    }
  }
  &__email-items {
    display: grid;
    grid-template-columns: 1fr;
  }
  &__email {
    font-size: 14px;
    color: $orangeColor;
    text-decoration: underline;
    transition: font-weight 0.2s;
    &:hover {
      font-weight: 500;
    }
  }
}
/** CONTACTS-INFO END **/

/** MAIN-FOOTER **/
.main-footer {
  background-color: $creamColor;
  margin-top: 136px;
  &__wrapper {
    width: 100%;
    max-width: 1528px;
    margin: auto;
  }
  &__content {
    display: grid;
    grid-template-columns: 352px 1fr 224px;
    grid-gap: 24px;
    align-items: center;
    margin: auto;
    padding: 32px 0;
  }
  &__logo {
    width: 100%;
    max-width: 352px;
    height: 80px;
  }
  &__text {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    text-align: center;
  }
  &__contacts {
    text-align: right;
  }
}
@media (max-width: 1023px) {
  .main-footer {
    &__content {
      grid-template-columns: 1fr;
    }
    &__logo {
      order: 1;
      margin: auto;
    }
    &__copyright {
      order: 3;
    }
    &__contacts {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 8px;
      order: 2;
      text-align: center;
    }
  }
}
@media (max-width: 767px) {
  .main-footer {
    margin-top: 80px;
  }
}
/** MAIN-FOOTER END **/

/** MODAL **/
.modal {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(48, 34, 31, 0.8);
  overflow-y: auto;
  z-index: 9999;
  &::-webkit-scrollbar {
    width: 8px;
    background-color: $lightGrayColor;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $darkCreamColor;
    border-radius: 8px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: $orangeColor;
  }
  &__overlay {
    display: flex;
    height: 100%;
  }
  &__content {
    position: relative;
    width: 100%;
    max-width: 488px;
    margin: auto;
  }
  &__close {
    position: absolute;
    right: 24px;
    top: 24px;
    width: 24px;
    height: 24px;
    &:hover {
      cursor: pointer;
    }
    &:before, &:after {
      position: absolute;
      left: 11px;
      content: '';
      height: 24px;
      width: 2px;
      background-color: $lightGrayColor;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}
/** MODAL END **/

/** POLICY-MODAL **/
.policy-modal {
  &__content {
    max-width: 800px;
    max-height: 100vh;
    margin: 80px auto;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 8px;
      background-color: $lightGrayColor;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $darkCreamColor;
      border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: $orangeColor;
    }
  }
  &__body {
    background-color: $whiteColor;
    padding: 64px 32px 32px;
  }
  &__title {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 16px;
  }
  &__text {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
    &--bold {
      font-weight: 600;
    }
  }
}
/** POLICY-MODAL END **/

/** CALLBACK-FORM **/
.callback-form {
  background-color: $whiteColor;
  &__wrapper {
    padding: 40px;
  }
  &__title {
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 24px;
  }
  &__course-name {
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
  }
  &__btn {
    height: 64px;
    width: 100%;
    max-width: 200px;
    margin: auto;
  }
}
/** CALLBACK-FORM END **/

/** MOVING-FORM **/
.moving-form {
  background-color: $whiteColor;
  &__wrapper {
    padding: 40px;
  }
  &__title {
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 24px;
  }
  &__course-name {
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
  }
  &__btn {
    height: 64px;
    width: 100%;
    max-width: 200px;
    margin: auto;
  }
}
/** MOVING-FORM END **/

/** RESULT-MODAL **/
.result-modal {
  &__content {
    background-color: $whiteColor;
  }
  &__body {
    text-align: center;
    padding: 64px 32px 32px;
  }
  &__top {
    margin-bottom: 40px;
  }
  &__title {
    font-size: 24px;
    margin-bottom: 24px;
  }
  &__text {
    &--bold {
      font-weight: 600;
    }
  }
}
/** RESULT-MODAL END **/

/** COOKIE-INFO **/
.cookie-info {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $whiteColor;
  padding: 16px 48px 16px 16px;
  box-shadow: 0 0 12px 0 $blackColor;
  z-index: 9999;
  &__text {
    font-size: 12px;
    text-align: center;
    line-height: 16px;
  }
  &__link {
    font-weight: 700;
    text-decoration: underline;
  }
  &__close {
    position: absolute;
    right: 8px;
    top: 0;
    bottom: 0;
    width: 32px;
    height: 32px;
    margin: auto;
    &:hover {
      cursor: pointer;
    }
    &:before, &:after {
      position: absolute;
      left: 11px;
      content: '';
      height: 32px;
      width: 2px;
      background-color: $lightGrayColor;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}
@media (max-width: 767px) {
  .cookie-info {
    padding: 8px 48px 8px 8px;
    &__text {
      text-align: left;
    }
  }
}
/** COOKIE-INFO END **/

/** COOKIE-MODAL **/
.cookie-modal {
  &__content {
    max-width: 800px;
    max-height: 100vh;
    margin: 80px auto;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 8px;
      background-color: $lightGrayColor;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $darkCreamColor;
      border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: $orangeColor;
    }
  }
  &__body {
    background-color: $whiteColor;
    padding: 64px 32px 32px;
  }
  &__title {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 16px;
  }
  &__text {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
    &--bold {
      font-weight: 600;
    }
  }
}
/** COOKIE-MODAL END **/

